
import { QUploader } from "quasar";
import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import { saveAs } from "file-saver";

@Component
export default class UploadCV extends Vue {
  @Prop(Boolean) isPopUp!: Boolean;

  loading = false;
  loading_curriculum = true;
  subir_archivo = false;

  data: Array<any> = [];

  columns = [
    {
      name: "id",
      label: "Id",
      align: "center",
      hidden: true,
      field: (row: any) => row.id,
      sortable: true
    },
    {
      name: "name",
      required: true,
      label: "Nombre del Archivo",
      align: "left",
      field: (row: any) => row.nombre,
      sortable: true
    },
    {
      name: "actions",
      required: true,
      label: "Acciones",
      align: "center",
      field: (row: any) => "--",
      sortable: false
    }
  ];

  subirCurriculum() {
    this.$q.loading.show();
    const formData = new FormData();
    let uploader = this.$refs.uploader as any;
    const files = uploader.files;

    if (!files || files.length == 0) {
      return this.$q.notify({
        message: "Debes subir un archivo para crearlo",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    }

    files.forEach((item: any, index: any) =>
      formData.append("my-file-" + index, item)
    );

    this.$axios
      .post("persona-archivos/subir/curriculum", formData, {
        params: {
          id_persona: Number(this.$store.state.user.user_id)
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.$q.notify({
          message: "Curriculum subido correctamente",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.subir_archivo = false;
        this.data = [response.data];
      })
      .catch(error => {
        this.$q.notify({
          message: "Hubo un problema subiendo el curriculum",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  bajarCurriculum() {
    this.$axios
      .get(
        "persona-archivos/front/bajar/" +
          this.$store.state.user.user_id.toString(),
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.auth_token
          },
          responseType: "blob"
        }
      )
      .then(response => {
        const content_disposition = response.headers["content-disposition"];
        const filename = content_disposition.split(";")[1].split("=")[1];
        saveAs(response.data, filename);
      })
      .catch(error => {
        this.$q.notify({
          message: error,
          color: "principal",
          position: "top",
          timeout: 15000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  mounted() {
    this.$axios
      .get("persona-archivos", {
        params: {
          filter: {
            where: {
              id_persona: Number(this.$store.state.user.user_id),
              nombre: { like: "%curriculum%" }
            },
            fields: ["id", "nombre"]
          }
        }
      })
      .then(response => {
        this.data = response.data;
      })
      .catch(error => {})
      .finally(() => {
        this.loading_curriculum = false;
      });
  }
}
